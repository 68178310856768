import React from 'react';
import './App.css';
import LeafletMap from "./components/leaflet/LeafletMap";
import SearchPanel from "./components/SearchPanel/SearchPanel";

function App() {
  return (
    <div className="App">
      <SearchPanel />
      <LeafletMap />
    </div>
  );
}

export default App;
