import React from 'react';
import './SearchPanel.css';
import SelectTransportCollection from "../SelectTransportCollection";

const SearchPanel = () => {
  
  const onChangeTransportCollection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
  }
  
  return (
    <div className="search-panel">
      <h1>Search Panel</h1>
      <SelectTransportCollection onChange={onChangeTransportCollection}/>
    </div>
  )
  
}

export default SearchPanel;