import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {LatLngExpression} from "leaflet";
import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';

const LeafletMap = () => {
  
  const position: LatLngExpression = [51.505, -0.09]
  
  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom={true} className="map-container">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default LeafletMap