import React from 'react';

interface SelectTransportCollectionProps {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
const SelectTransportCollection = (props: SelectTransportCollectionProps) => {
  
  const onChangeTransportCollection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
  }
  
  return (
    <select onChange={onChangeTransportCollection}>
      <option value="1">1</option>
      <option value="2">2</option>
    </select>
  )
  
}

export default SelectTransportCollection;